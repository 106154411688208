<template>
  <!-- Main Content -->
  <div id="content" class="mb-5">
    <!-- Begin Page Content -->
    <div id="meus-contratos" class="container-fluid">
      <div class="d-sm-flex align-items-center justify-content-between my-4 pt-2 mx-md-4">
        <h1 class="h3 mb-0">Meus Contratos</h1>
      </div>
      <div class="alert alert-warning" role="alert" v-if="exibeErrorMessage">
        {{ errorMessage }}
      </div>
      <div v-if="carregando" class="text-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        Carregando contratos ... 
      </div>
      <div v-if="semContratos">
        Você não possui contratos ativos.
      </div> 
      <div class="row row-list-cards">
        <div class="item" v-for="(contrato, index) in contratos" v-bind:key="index" >
          <ContratoItem :contrato="contrato"></ContratoItem>
        </div>
        
      </div>
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- End of Main Content -->
</template>


<script>

import ContratoItem from "../components/ContratoItem";
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import AuthServiceContext from "../services/AuthService";

export default {
  name: "meuscontratos",
  components:{
    ContratoItem
  },
  data() {
    return {
      authService: AuthServiceContext.getInstance(),
      contratos:[],
      errorMessage:'',
      primeiraConsulta: false
    }
  },
  computed: {
    exibeErrorMessage: function() {
      return this.errorMessage != '';
    },
    carregando: function() {
      return !this.primeiraConsulta;
    },
    semContratos: function() {
      return this.errorMessage == '' && this.contratos.length == 0 && this.primeiraConsulta;
    }
  },
  mounted() {

    this.$nextTick(() => {

      let config = this.authService.config();
      let contratosApi = new ApiPortalAutoAtendimento.ContratoApi();
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.basePath = config.endpoints.autoatendimento;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken();
      let userData = this.authService.userData();

      let empresa = userData.Empresa; 
      let cpfcliente = userData.CpfCnpj; //TODO
      let situacao = "T";                //TODO

      if (cpfcliente == ""){
        cpfcliente = localStorage.cpfCnpj;
      }

      contratosApi.consultaContratosPorCliente(empresa, cpfcliente, situacao, (error, data, response) => {
        this.primeiraConsulta = true;
        if (error) {
          this.errorMessage = 'Erro ao obter contratos.';
        } else {
          this.contratos = data;
          this.errorMessage = '';
        }
      });
    
    });
  }
};
</script>
